.merchantDialog{
  z-index: 1299 !important;
}
.draggingItemBG{
  position: relative;
  width:  calc(100% - 1px);
  height: 76px;
}
.draggingItemBG:last-child{
  height: 75px
}
.draggingItemBG::after{
  top: 1px;
  left: 0;
  position: absolute;
  width: calc(100% - 1px);
  height: 100%;
  content: '';
  border: 0.5px dashed #219653;
  border-bottom: 1px solid #219653;
}
.draggingItemBG_hotKey{
  position: relative;
  width:  calc(100% - 1px);
  height: 46px;
}

.draggingItemBG_hotKey:last-child{
  height: 45px
}
.draggingItemBG_hotKey::after{
  top: 0;
  left: 0;
  position: absolute;
  width: calc(100% - 1px);
  height: 100%;
  content: '';
  border: 0.5px dashed #219653;
  border-bottom: 1px solid #219653;
}
