/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
  // max-width: 1280px;
  position: relative;
}

.app-wrapper {
  padding: 30px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}

.content-row-wrap {
  @include display-flex(flex, row, wrap);
  @include align-items(start);
}
.content-col-wrap {
  @include display-flex(flex, col, wrap);
  @include align-items(start);
}

.tabSwitchStyle{
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  .borderB{
    position: relative;
    &:after{
      position: absolute;
      height: 2px;
      width: 100%;
      content: '';
      bottom: -2px;
      background-color: #27AE60;
    }
  }
}

.noBorderSelect{
  .MuiOutlinedInput-notchedOutline{
    border:none
  }
}