// Box Shadow
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  box-shadow: $shadow;
}

// Single side border-radius
@mixin border-radius($radius...) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Single side box-sizing
@mixin box-sizing($box-sizing) {
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}

// Clearfix

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}

// Transitions
@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin translate($translate) {
  -webkit-transform: translate($translate);
  -ms-transform: translate($translate);
  transform: translate($translate);
}

@mixin translateX($translate) {
  -webkit-transform: translateX($translate);
  -ms-transform: translateX($translate);
  transform: translateX($translate);
}

@mixin translateY($translate) {
  -webkit-transform: translateY($translate);
  -ms-transform: translateY($translate);
  transform: translateY($translate);
}

//Background Image
@mixin background($imgpath,$position:0 0,$repeat: no-repeat,$size: cover) {
  background: {
    image: url($imgpath);
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}


// Flex
@mixin display-flex($flex: flex, $flex-direction: row, $flex-wrap: wrap) {
  display: #{'-webkit'}-#{$flex};
  display: #{'-moz'}-#{$flex};
  display: #{'-ms'}-#{$flex};
  display: #{'-o'}-#{$flex};
  display: $flex;
  -webkit-flex-direction: $flex-direction;
  -ms-flex-direction: $flex-direction;
  flex-direction: $flex-direction;
  -webkit-flex-wrap: $flex-wrap;
  -ms-flex-wrap: $flex-wrap;
  flex-wrap: $flex-wrap;
}

@mixin flex($flex-grow: 1, $flex-shrink: 1, $flex-basis: auto) {
  -webkit-flex: $flex-grow $flex-shrink $flex-basis;
  -ms-flex: $flex-grow $flex-shrink $flex-basis;
  flex: $flex-grow $flex-shrink $flex-basis;
}

@mixin justify-content($justify-content) {
  -webkit-justify-content: $justify-content;
  justify-content: $justify-content;
}

@mixin align-items($align-items) {
  -webkit-align-items: $align-items;
  align-items: $align-items;
}

//Size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}


.flex-row {
  @include display-flex(flex, row, nowrap);
}

.flex {
  display: flex;
  @include flex(1, 0);
}

.align-center {
  @include align-items(center)
}

//Border Radius

// Single side border-radius

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin border-right-radius($radius) {
  @if $enable-rounded {
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}

@mixin border-bottom-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin border-left-radius($radius) {
  @if $enable-rounded {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

//Mixin Hover

// stylelint-disable indentation
@mixin hover {
  // TODO: re-enable along with mq4-hover-shim
  //  @if $enable-hover-media-query {
  //    // See Media Queries Level 4: https://drafts.csswg.org/mediaqueries/#hover
  //    // Currently shimmed by https://github.com/twbs/mq4-hover-shim
  //    @media (hover: hover) {
  //      &:hover { @content }
  //    }
  //  }
  //  @else {
  &:hover {
    @content;
  }
  //  }
}

@mixin hover-focus {
  @if $enable-hover-media-query {
    &:focus {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin plain-hover-focus {
  @if $enable-hover-media-query {
    &,
    &:focus {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &,
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin hover-focus-active {
  @if $enable-hover-media-query {
    &:focus,
    &:active {
      @content;
    }
    @include hover {
      @content;
    }
  } @else {
    &:focus,
    &:active,
    &:hover {
      @content;
    }
  }
}

// Gradient Mixin
@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-image: linear-gradient($deg, $start-color, $end-color);
  background-repeat: repeat-x;
}

@mixin gradient-x($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}

// Spacing

@mixin generate-margin-padding-in-rem($from, $to) {
  @for $i from $from through $to {
    .m-#{$i} {
      margin: #{$i * 0.25}rem;
    }
    .mt-#{$i} {
      margin-top: #{$i * 0.25}rem !important;
    }
    .mr-#{$i} {
      margin-right: #{$i * 0.25}rem !important;
    }
    .mb-#{$i} {
      margin-bottom: #{$i * 0.25}rem !important;
    }
    .ml-#{$i} {
      margin-left: #{$i * 0.25}rem !important;
    }
    .mx-#{$i} {
      margin-left: #{$i * 0.25}rem !important;
      margin-right: #{$i * 0.25}rem !important;
    }
    .my-#{$i} {
      margin-top: #{$i * 0.25}rem !important;
      margin-bottom: #{$i * 0.25}rem !important;
    }

    .p-#{$i} {
      padding: #{$i * 0.25}rem !important;
    }
    .pt-#{$i} {
      padding-top: #{$i * 0.25}rem !important;
    }
    .pr-#{$i} {
      padding-right: #{$i * 0.25}rem !important;
    }
    .pb-#{$i} {
      padding-bottom: #{$i * 0.25}rem !important;
    }
    .pl-#{$i} {
      padding-left: #{$i * 0.25}rem !important;
    }
    .px-#{$i} {
      padding-left: #{$i * 0.25}rem !important;
      padding-right: #{$i * 0.25}rem !important;
    }
    .py-#{$i} {
      padding-top: #{$i * 0.25}rem !important;
      padding-bottom: #{$i * 0.25}rem !important;
    }
  }
}

@mixin generate-margin-padding-in-px($from, $to) {
  @for $i from $from through $to {
    .m-#{$i}px {
      margin: #{$i}px;
    }
    .mt-#{$i}px {
      margin-top: #{$i}px !important;
    }
    .mr-#{$i}px {
      margin-right: #{$i}px !important;
    }
    .mb-#{$i}px {
      margin-bottom: #{$i}px !important;
    }
    .ml-#{$i}px {
      margin-left: #{$i}px !important;
    }
    .mx-#{$i}px {
      margin-left: #{$i}px !important;
      margin-right: #{$i}px !important;
    }
    .my-#{$i}px {
      margin-top: #{$i}px !important;
      margin-bottom: #{$i}px !important;
    }

    .p-#{$i}px {
      padding: #{$i}px !important;
    }
    .pt-#{$i}px {
      padding-top: #{$i}px !important;
    }
    .pr-#{$i}px {
      padding-right: #{$i}px !important;
    }
    .pb-#{$i}px {
      padding-bottom: #{$i}px !important;
    }
    .pl-#{$i}px {
      padding-left: #{$i}px !important;
    }
    .px-#{$i}px {
      padding-left: #{$i}px !important;
      padding-right: #{$i}px !important;
    }
    .py-#{$i}px {
      padding-top: #{$i}px !important;
      padding-bottom: #{$i}px !important;
    }
  }
}

@mixin generate-height-width($from, $to) {
  @for $i from $from through $to {
    @if $i % 4 == 0 {
      .w-#{$i} {
        width: #{$i}px !important;
      }
      .min-w-#{$i} {
        min-width: #{$i}px !important;
      }
      .max-w-#{$i} {
        max-width: #{$i}px !important;
      }
      .h-#{$i} {
        height: #{$i}px !important;
      }
      .min-h-#{$i} {
        min-height: #{$i}px !important;
      }
      .max-h-#{$i} {
        max-height: #{$i}px !important;
      }
    }
  }
}


.w-full {
  width: 100%;
}
.w-full-screen {
  width: 100vw;
}
.min-w-750 {
  min-width: 750px;
}
.max-w-770 {
  max-width: 770px;
}

.h-full {
  height: 100% !important;
}
.h-full-screen {
  height: 100vh;
}
.h-150px {
  height: 150px !important;
}

.size-36 {
  height: 36px !important;
  width: 36px !important;
}
.size-24 {
  height: 24px !important;
  width: 24px !important;
}

// media
@mixin media($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

// Animation
@mixin keyframeMaker($name) {
  @keyframes #{$name} {
    @content;
  }
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
}

@include generate-margin-padding-in-rem(0, 25);
@include generate-margin-padding-in-rem(0, -25);
@include generate-margin-padding-in-px(1, 16);
@include generate-height-width(0, 880);

